@import "src/theme/colors.sass"
.projectsPage
  position: relative
  width: 100%
  max-width: 72rem
  margin: 0 auto 4rem auto
  padding: 8rem 1.2rem 0 1.2rem
  align-self: center
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-auto-rows: 1fr
  justify-items: center

  .backWrapper
    position: absolute
    display: flex
    align-items: center
    gap: 1rem
    font-size: 1.1rem
    color: $primaryText
    left: 1.2rem
    top: 5rem
    text-decoration: none
    transition: .35s

    &:hover
      color: $primary1

  .hoverDim
    position: absolute
    background-color: $specialLowerOpacity
    opacity: 0
    visibility: hidden
    transition: 1s
    backdrop-filter: blur(2px) grayscale(100%) brightness(80%)
    transform: translate(25px, 25px)

    &.hover
      visibility: visible
      opacity: 1

@media screen and (max-width: 1299px)
  .projectsPage
    .backWrapper
      top: 6rem

@media screen and (max-width: 850px)
  .projectsPage
    grid-template-columns: repeat(2, 1fr)

@media screen and (max-width: 550px)
  .projectsPage
    grid-template-columns: repeat(1, 1fr)

    .backWrapper
      font-size: 1rem