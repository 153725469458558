@import "src/theme/colors.sass"
.projectItem
  position: relative
  overflow: hidden

  a
    text-decoration: none

    &:hover
      span
        visibility: visible
        opacity: 1

    img
      width: 100%
      height: 100%
      object-fit: cover

    span
      position: absolute
      content: 'Show More'
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      font-size: 1.4rem
      top: 0
      left: 0
      visibility: hidden
      opacity: 0
      transition: 1s
      color: $white
      z-index: 1