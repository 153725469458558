@import "src/theme/colors.sass"
.projectWrapper
  overflow: hidden

  a
    text-decoration: none
    color: $primaryText
    position: relative

    img
      display: block
      object-fit: cover
      width: 100%
      height: 100%

    .showMore
      position: absolute
      background-color: $primaryBg
      bottom: -3rem
      left: 0
      padding: .8rem
      font-size: .9rem
      transition: .40s

    &:hover
      .showMore
        bottom: 0