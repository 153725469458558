@import "src/theme/colors.sass"
.skillsContainer
  position: relative

  .skills
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 1.6rem
    padding: 6rem 8rem

@media screen and (max-width: 1000px)
  .skillsContainer
    .skills
      padding: 4rem 5rem

@media screen and (max-width: 770px)
  .skillsContainer
    .skills
      padding: 1.5rem