\:root
  --black: #000
  --white: #fff

  --primaryText: #fff
  --secondaryText: #605777
  --secondary2Text: #605777

  --primaryBg: #171321

  --primary1: #f393a4
  --primary1Darker: #{darken(#f393a4, 12%)}
  --secondary1: #f8f3f7

  --primary2: #362f47
  --secondary2: #eae0e9

  --primary3: #7e5a7b
  --secondary3: #f8f3f7

  --special: #605777
  --specialLowerOpacity: #60577777

  --success: #46b450
  --danger: #bb1d3b


$black: var(--black)
$white: var(--white)

$primaryText: var(--primaryText)
$secondaryText: var(--secondaryText)
$secondary2Text: var(--secondary2Text)

$primaryBg: var(--primaryBg)

$primary1: var(--primary1)
$primary1Darker: var(--primary1Darker)
$secondary1: var(--secondary1)

$primary2: var(--primary2)
$secondary2: var(--secondary2)

$primary3: var(--primary3)
$secondary3: var(--secondary3)

$special: var(--special)
$specialLowerOpacity: var(--specialLowerOpacity)

$success: var(--success)
$danger: var(--danger)