@import "src/theme/colors.sass"
.home
  position: relative
  height: 100vh
  padding: 4rem
  display: flex
  align-items: center
  gap: 4rem

  .imageContainer
    position: relative
    display: flex
    justify-content: center
    align-items: center
    background-color: $secondary2
    border-radius: 50%
    height: clamp(15rem, 27vw, 30rem)
    width: clamp(15rem, 27vw, 30rem)

    &::after
      position: absolute
      content: ''
      bottom: 0
      left: 0
      width: 100%
      height: 4rem
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $primaryBg)

  .introduce
    flex: 1
    display: flex
    flex-direction: column
    align-items: start
    gap: 3rem

    .title
      font-size: clamp(2rem, 7.5vw, 5.4rem)
      font-weight: 300
      line-height: clamp(2.2rem, 8vw, 5.5rem)

      span
        font-weight: 500
        display: block
        font-size: clamp(1rem, 6.6vw, 4.5rem)

    .description
      line-height: 1.9rem

@media screen and (max-width: 770px)
  .home
    flex-direction: column
    align-items: start
    height: auto
    padding-top: 6rem

@media screen and (max-width: 410px)
  .home
    padding: 5rem 1.5rem 1.5rem
    align-items: center
    gap: 2rem

    .introduce
      gap: 2rem