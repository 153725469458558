@import "src/theme/colors.sass"
header
  position: fixed
  top: 0
  right: 0
  left: 0
  background-color: $primaryBg
  padding: 1.3rem
  display: flex
  justify-content: space-between
  align-items: center
  box-shadow: none
  overflow: hidden
  z-index: 6

  &.open
    .socialMedias
      right: 8.3rem

  span.logo
    font-weight: 700
    font-size: 1.1rem
    text-transform: uppercase
    letter-spacing: .4rem
    visibility: visible
    opacity: 1

  .wrapper
    display: flex
    align-items: center
    gap: 1.2rem

    .themeButton
      border-radius: 50%
      width: 30px
      height: 30px
      position: relative
      box-shadow: inset 10px -10px 0 0 $primaryText
      transform: scale(1) rotate(-2deg)
      transition: box-shadow .5s ease 0s, transform .4s ease .1s

      &:before
        content: ''
        width: inherit
        height: inherit
        border-radius: inherit
        position: absolute
        left: 0
        top: 0
        transition: background .3s ease

      &:after
        content: ''
        width: 6px
        height: 6px
        border-radius: 50%
        margin: -3px 0 0 -3px
        position: absolute
        top: 50%
        left: 50%
        box-shadow: 0 -20px 0 $primaryText, 0 20px 0 $primaryText, 20px 0 0 $primaryText, -20px 0 0 $primaryText, 14px 14px 0 $primaryText, -14px 14px 0 $primaryText, 14px -14px 0 $primaryText, -14px -14px 0 $primaryText
        transform: scale(0)
        transition: all .3s ease

      &.light
        box-shadow: inset 22px -22px 0 0 $primaryText
        transform: scale(.5) rotate(0deg)
        transition: transform .3s ease .1s, box-shadow .2s ease 0s

        &:before
          background: $primaryText
          transition: background .3s ease .1s

        &:after
          transform: scale(1.5)
          transition: transform .5s ease .15s

  .socialMedias
    position: absolute
    display: flex
    align-items: center
    gap: 1.8rem
    font-size: .8rem
    transition: .35s
    right: -100%

    a
      color: $primaryText
      text-decoration: none
      text-transform: uppercase
      letter-spacing: .4rem
      transition: .35s

      &:hover
        color: $primary1

@media screen and (min-width: 1300px)
  header
    flex-direction: column
    align-items: end
    justify-content: start
    gap: 3rem
    padding: 2rem 1.3rem
    right: unset
    bottom: 0
    width: 5rem

    span.logo
      white-space: nowrap
      order: 2
      transform: rotate(-90deg) translate(-6rem, 5.7rem)

    .wrapper
      justify-content: center
      flex-direction: column-reverse
      gap: 1.8rem
      order: 1

    .socialMedias
      position: static
      order: 3
      transition: unset
      transform: rotate(-90deg) translate(-15rem, 2rem)

@media screen and (max-width: 570px)
  header
    &.open
      span.logo
        visibility: hidden
        opacity: 0

@media screen and (max-width: 410px)
  header
    padding: .7rem 1rem

    span.logo
      font-size: .9rem

    .wrapper
      gap: .7rem

      .themeButton
        width: 25px
        height: 25px
        box-shadow: inset 7px -7px 0 0 $primaryText

        &:after
          width: 5px
          height: 5px
          margin: -2.5px 0 0 -2.5px
          box-shadow: 0 -16px 0 $primaryText, 0 16px 0 $primaryText, 16px 0 0 $primaryText, -16px 0 0 $primaryText, 12px 12px 0 $primaryText, -12px 12px 0 $primaryText, 12px -12px 0 $primaryText, -12px -12px 0 $primaryText