@import "src/theme/colors.sass"
.footer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: 6rem
  gap: 1rem

  .socialsMedias
    display: flex
    align-items: center
    gap: 2rem

    span
      a
        font-size: 1.8rem

  .copyright
    text-align: center

  .copyright,.socialsMedias
    a
      color: $primaryText
      text-decoration: none
      transition: .35s

      &:hover
        color: $primary1
