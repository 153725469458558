@font-face
  font-family: montserrat
  src: url('./assets/fonts/montserrat/Montserrat-Light.ttf')
  font-weight: 300

@font-face
  font-family: montserrat
  src: url('./assets/fonts/montserrat/Montserrat-Regular.ttf')
  font-weight: 400

@font-face
  font-family: montserrat
  src: url('./assets/fonts/montserrat/Montserrat-Medium.ttf')
  font-weight: 500

@font-face
  font-family: montserrat
  src: url('./assets/fonts/montserrat/Montserrat-SemiBold.ttf')
  font-weight: 600

@font-face
  font-family: montserrat
  src: url('./assets/fonts/montserrat/Montserrat-Bold.ttf')
  font-weight: 700