@import "src/theme/colors.sass"
.contactContainer
  position: relative

  .contact
    padding: 6rem 8rem
    color: $secondaryText

    p
      font-size: 1.2rem
      font-weight: 500
      margin-bottom: 5rem

    .contactForm
      position: relative
      display: flex
      flex-direction: column
      gap: 3.4rem

      &.invalid
        padding-bottom: 3rem

        &:after
          position: absolute
          content: 'One or more fields have an error. Please check and try again.'
          color: $secondary1
          background-color: $primary1
          padding: .4rem
          font-size: .8rem
          text-align: center
          bottom: 0
          left: 0
          right: 0

        .inputWrapper.required
          &:after
            position: absolute
            content: 'The field is required.'
            bottom: .1rem
            right: 0
            text-align: center
            color: $primaryText
            background-color: $primary1
            padding: .4rem
            font-size: .7rem

      .inputWrapper
        position: relative
        display: flex
        flex-direction: column

        label
          position: absolute
          top: -1.5rem
          font-weight: 600
          font-size: 1.2rem
          transition: .3s

          &:has(+ input:focus,+ textarea:focus)
            font-size: .8rem

        input, textarea
          border: none
          background-color: inherit
          border-bottom: 2px solid $secondaryText
          padding: .4rem
          font-size: 1.1rem
          color: $secondaryText

          &:focus
            outline: none

        textarea
          resize: vertical
          font-family: montserrat

      button
        position: relative
        padding: 1.4rem
        border: 2px solid $secondaryText
        background-color: inherit
        display: flex
        justify-content: center
        overflow: hidden

        span
          z-index: 1
          font-size: 1.2rem
          font-weight: 600
          color: $secondaryText
          transition: .6s

        &::after
          content: ''
          position: absolute
          aspect-ratio: 1
          width: 0
          top: var(--y, 0px)
          left: var(--x, 0px)
          border-radius: 50%
          transform: translate(-50%, -50%)
          background-color: $secondaryText
          transition: .6s ease-out, top 0s, left 0s

        &:hover
          span
            color: $secondary1

          &::after
            width: 65rem

        .loader
          position: relative
          width: 2rem
          height: 2rem
          background-color: $primaryBg
          border-radius: 50%
          animation: 1s loaderAnimation infinite linear

          &:after
            position: absolute
            content: ''
            display: block
            width: .7rem
            height: .7rem
            top: .3rem
            left: .3rem
            background-color: $primary1
            border-radius: 50%

      .messageStatus
        padding: .7rem 1rem
        display: none

        &.show
          display: block

          &.success
            border: 2px solid $success

          &.wrong
            border: 2px solid $danger

@keyframes loaderAnimation
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)

@media screen and (max-width: 1000px)
  .contactContainer
    .contact
      padding: 4rem 5rem

@media screen and (max-width: 770px)
  .contactContainer
    .contact
      padding: 1.5rem