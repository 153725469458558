@import "src/theme/colors.sass"
.notFoundPage
  position: relative
  width: 100%
  max-width: 72rem
  margin: 0 auto 4rem auto
  padding-top: 6rem
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 2.5rem
  color: $primaryText

  h3
    font-size: 2.5rem

  p
    font-size: 1.6rem
    text-align: center
    padding: 0 1rem

  span
    font-size: 17rem
    font-weight: 700
    color: $primary1

  .tryAgain
    font-size: 1rem

    a
      text-decoration: none
      color: $primary1

@media screen and (max-width: 750px)
  .notFoundPage
    span
      font-size: 6rem
