@import "src/theme/colors.sass"
.widget
  background-color: var(--widgetBg)
  padding: 9rem 0
  flex: 1
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: .3rem
  --text-color: #{$secondary1}

  .icon
    fill: var(--icon)

  .bg
    background-color: var(--bg)

  &.second
    --widgetBg: #{$primary2}
    --icon: #{$secondary1}
    --bg: #{$secondary2Text}

  &.first
    --widgetBg: #{$primary1}
    --icon: #{$primary2}
    --bg: #{$secondary1}

  &.third
    --widgetBg: #{$primary3}
    --text-color: #{$secondary3}
    --icon: #{$primary2}
    --bg: #{$secondary1}

  img
    width: 10rem
    height: 10rem
    object-fit: contain

  .text
    font-size: .8rem
    font-weight: 600
    text-transform: uppercase
    letter-spacing: .1rem
    color: var(--text-color)

@media screen and (max-width: 770px)
  .widget
    padding: 02rem
