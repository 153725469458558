@import "src/theme/colors.sass"
.app
  cursor: none

  .cursor
    position: fixed
    width: 10px
    height: 10px
    background-color: $primary1Darker
    border-radius: 50%
    z-index: 1000
    pointer-events: none
    transition: transform ease-out 5ms, background-color .3s

    &.pointer
      display: none

  .stalker
    position: fixed
    margin-top: -10px
    margin-left: -10px
    width: 30px
    height: 30px
    border: 2px solid $primary1Darker
    border-radius: 50%
    z-index: 999
    pointer-events: none
    transition: transform ease-out 160ms, width ease .3s, height ease .3s, margin-top .3s, margin-left .3s, border-color .3s

    &.pointer
      border-color: $primary2
      width: 50px
      height: 50px
      margin-top: -20px
      margin-left: -20px

@media screen and (max-width: 460px)
  .app
    .cursor
      display: none

    .stalker
      display: none