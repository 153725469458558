@import "src/theme/colors.sass"
.experienceContainer
  position: relative

  .experience
    padding: 6rem 8rem
    color: $secondaryText

    p
      font-size: 1.2rem
      font-weight: 500
      margin-bottom: 3rem

    .experienceItems
      display: flex
      flex-direction: column
      gap: 1.5rem

@media screen and (max-width: 1000px)
  .experienceContainer
    .experience
      padding: 4rem 5rem

@media screen and (max-width: 770px)
  .experienceContainer
    .experience
      padding: 1.5rem