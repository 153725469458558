@import "src/theme/colors.sass"
.educationContainer
  position: relative

  .education
    display: flex
    flex-direction: column
    gap: 4rem
    padding: 6rem 8rem
    color: $secondaryText

@media screen and (max-width: 1000px)
  .educationContainer
    .education
      padding: 4rem 5rem

@media screen and (max-width: 770px)
  .educationContainer
    .education
      padding: 1.5rem