@import "src/theme/colors.sass"
.sidebar
  position: fixed
  top: 4rem
  right: -100%
  padding: 3rem 7.3rem 3rem 3rem
  z-index: 5
  height: 100vh
  background-color: $primaryBg
  transition: .35s

  .list
    position: relative
    display: flex
    list-style: none
    flex-direction: column
    margin-right: 2rem
    gap: 2rem

    li
      a
        text-decoration: none
        text-transform: capitalize
        color: $primaryText
        font-weight: 500
        transition: .3s

        &:hover
          color: $primary1

        &.active
          color: $primary1

    .line
      position: absolute
      height: 3px
      background-color: $primary1
      bottom: -.5rem
      z-index: 1
      transition: .3s

.sidebar.open
  right: 0

@media screen and (min-width: 1300px)
  .sidebar
    right: 0
    left: 0
    top: -10%
    height: auto
    padding: 2rem

    .list
      flex-direction: row
      justify-content: center

  .sidebar.open
    top: 0

@media screen and (max-width: 570px)
  .sidebar
    width: 100%
    padding: 3rem
    display: flex
    justify-content: center

@media screen and (max-width: 410px)
  .sidebar
    top: 1.5rem