@import "src/theme/colors.sass"
.mainContainer
  width: 100%
  display: flex
  align-items: center
  color: $secondary2Text
  gap: 1.7rem

  .rangeContainer
    flex: 1
    background-color: $white
    height: .8rem

    .range
      background-color: $special
      color: $secondary1
      display: block
      padding: .3rem .6rem
      margin-top: -.3rem
      text-transform: uppercase
      letter-spacing: .1rem
      font-size: .7rem
      white-space: nowrap

      &.rangeAnimation
        animation: .5s rangeWidthAnimation linear
        animation-fill-mode: forwards

  .percent
    font-size: 1.3rem
    font-weight: 500

@keyframes rangeWidthAnimation
  from
    width: 0