@import "src/theme/colors.sass"
.template
  display: flex

  &.ltr
    flex-direction: row

  &.rtl
    flex-direction: row-reverse

  &.first
    --titleContainerBg: #{$primary1}
    --childrenContainerBg: #{$secondary1}

  &.second
    --titleContainerBg: #{$primary2}
    --childrenContainerBg: #{$secondary2}

  &.third
    --titleContainerBg: #{$primary3}
    --childrenContainerBg: #{$secondary1}

    .title
      color: $secondary3 !important

  .titleContainer
    background-color: var(--titleContainerBg)
    flex: 1
    display: flex
    justify-content: center
    align-items: center

    .title
      font-size: 3.3rem
      font-weight: 300
      letter-spacing: -.2rem
      color: $secondary1

  .childrenContainer
    background-color: var(--childrenContainerBg)
    flex: 2

@media screen and (max-width: 1000px)
  .template
    .titleContainer
      .title
        font-size: 2.5rem

@media screen and (max-width: 770px)
  .template
    flex-direction: column !important

    .titleContainer
      padding: 1.5rem

@media screen and (max-width: 410px)
  .template
    .titleContainer
      padding: 1.2rem

      .title
        font-size: 1.9rem