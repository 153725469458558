@import "src/theme/colors.sass"
@import "fonts"

*
  padding: 0
  margin: 0
  box-sizing: border-box
  user-select: none
  cursor: none

body
  font-family: montserrat
  background-color: $primaryBg
  color: $primaryText
  overflow-x: hidden

  &[data-theme = 'light']
    --primaryText: #7a5932
    --secondaryText: #7a5932
    --secondary2Text: #bea17d

    --primaryBg: #faeddf

    --primary1: #188497
    --primary1Darker: #{lighten(#188497,16%)}
    --secondary1: #fcf7f1

    --primary2: #f8a577
    --secondary2: #f0deca

    --special: #188497
    --specialLowerOpacity: #18849744
    --primary3: #fff48f
    --secondary3: #9e7e63

::-webkit-scrollbar
  width: 7px

::-webkit-scrollbar-track
  background: rgba(0, 0, 0, 0)

::-webkit-scrollbar-thumb
  background: $special
  border-radius: 2rem
