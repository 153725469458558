@import "src/theme/colors.sass"
.iconWrapper
  position: relative
  margin-bottom: 2.3rem
  transform: translateX(-.7rem)

  .fontawesome
    position: relative
    width: 5rem
    height: 5rem
    z-index: 1

  .bg
    position: absolute
    width: 5.5rem
    height: 5.5rem
    top: 1rem
    left: 2rem
    border-radius: 2rem .4rem
