@import "src/theme/colors.sass"
.widgetContainer
  position: relative

  .widgets
    display: flex

@media screen and (max-width: 770px)
  .widgetContainer
    .widgets
      flex-direction: column