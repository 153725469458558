@import "src/theme/colors.sass"
.about
  padding: 6rem 8rem
  color: $secondaryText

  >*:not(:last-child)
    margin-bottom: 3rem

  h3,p
    line-height: 2rem

  h3
    font-size: 1.3rem
    font-weight: 600

  p
    font-size: 1rem

  .aboutContainer
    display: flex
    justify-content: space-between
    align-items: center

    .list
      list-style: none

      &> *:not(:last-child)
        margin-bottom: .8rem

      li
        .label
          font-weight: 600
          text-transform: uppercase
          font-size: 1rem

        .value
          margin-inline-start: .4rem
          font-weight: 400
          font-size: 1rem

    .downloadCvContainer
      a
        display: flex
        flex-direction: column
        align-items: center
        gap: 1rem
        color: inherit
        text-decoration: none

        .icon
          fill: $secondaryText

        .bg
          background-color: $primary1

        .downloadCv
          font-size: .8rem
          font-weight: 600
          text-transform: uppercase
          letter-spacing: .1rem

@media screen and (max-width: 1000px)
  .about
    padding: 4rem 5rem

@media screen and (max-width: 900px)
  .about
    >*:not(:last-child)
      margin-bottom: 2rem

    .aboutContainer
      flex-direction: column
      align-items: start
      gap: 2rem

      .downloadCvContainer
        margin: auto

@media screen and (max-width: 770px)
  .about
    padding: 1.5rem