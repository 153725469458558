@import "src/theme/colors.sass"
.portfolioContainer
  position: relative

  .portfolio
    display: flex
    flex-direction: column
    align-items: center

    .projects
      display: grid
      grid-template-columns: repeat(2, 1fr)

    .loadMoreButton
      text-align: center
      width: 100%
      background-color: $primary1

      a
        color: $secondary1
        text-transform: uppercase
        text-decoration: none
        font-weight: 600
        font-size: 1.2rem
        transition: .35s
        display: block
        padding: 1.5rem

        &:hover
          color: $primary1Darker

@media screen and (max-width: 770px)
  .portfolioContainer
    .portfolio
      .projects
        grid-template-columns: 1fr